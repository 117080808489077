<template>
  <div class="ApuracaoAcaoDetalhesNova">
    <apuracao-acao-detalhes-resumo-execucao
      v-if="resumoCarregado"
      :resumo-exec="resumoExec"/>
    <apuracao-acao-detalhes-produtos
      v-if="resumoCarregado"
      :resumo-exec="resumoExec"/>
    <v-row class="my-3 mx-0">
      <v-spacer></v-spacer>
      <v-btn id="btn_voltar"
        @click.native="voltar">
        {{ $t('label.voltar') }}
      </v-btn>
    </v-row>
  </div>
</template>
<script>
import ApuracaoAcaoDetalhesResumoExecucao from './ApuracaoAcaoDetalhesResumoExecucao';
import ApuracaoAcaoDetalhesProdutos from './ApuracaoAcaoDetalhesProdutos';

export default {
  name: 'ApuracaoAcaoDetalhesNova',
  data() {
    return {
      resumoExec: null,
      resumoCarregado: false,
      manterExpandido: [],
    };
  },
  components: {
    ApuracaoAcaoDetalhesResumoExecucao,
    ApuracaoAcaoDetalhesProdutos,
  },
  mounted() {
    if (this.$route.params && this.$route.params.manterExpandido) {
      this.manterExpandido = this.$route.params.manterExpandido;
    }
    this.resumoExec = this.$route.params.resumoExec;
    this.resumoCarregado = true;
  },
  methods: {
    voltar() {
      const panelExpandido = this.manterExpandido;
      const { filtrosAplicados } = this.$route.params;
      this.$router.push({
        name: this.$route.params.from || 'apuracao-acao',
        params: { panelExpandido, filtrosAplicados },
      });
    },
  },
};
</script>
